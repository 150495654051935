import React from 'react'
import Layout from '../components/layout'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

function index() {
  return (
    <Layout>
      <div className='container'>
        <h1>CSS is mijn tweede moedertaal</h1>
        <StaticImage src="../images/ikke.jpg" alt="Bart Sallé" layout="fixed" width={200} height={200} className="alignright bart" />
        <p>Ik ben Bart Sallé, gepassioneerd webdeveloper en ik doe veel frontend werk waarbij CSS styling mijn specialiteit is.</p>
        <p>Mijn werkzaamheden lopen uiteen van het stylen van een Wordpress website (of WooCommerce shop) tot diverse frontend frameworks zoals Laravel, Gatsby of andere op PHP of Javascript/React gebaseerde websites of applicaties.</p>
        <p><Link to="/diensten/">Lees meer</Link> over mij en wat ik eventueel voor jou kan betekenen.</p>
      </div>
    </Layout>
  )
}

export default index